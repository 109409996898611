import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import OpportunityWallStack from 'domain/opportunity-wall';
import { CreateOpportunityStack } from 'domain/create-opportunity';
import InAppBrowser from 'domain/opportunity-wall/screens/in-app-browser';
import OldInAppBrowser from 'components/organisms/in-app-browser';

import { Platform, PlatformIOSStatic } from 'react-native';
import ScannerScreen from 'domain/create-opportunity/screens/scanner';
import { IS_CHROME_EXTENSION } from 'domain/shared/constants';
import useHideBottomBar from 'domain/shared/hooks/use-hide-bottom-bar';
import { OpportunityScreens } from './screens';
import ChromeExtension from './screens/chrome-extension';

import LeadHub from 'domain/lead-hub/screens/list';
import LeadHubSearch from 'domain/lead-hub/screens/search';


const Stack = createNativeStackNavigator();

export const presentation = (Platform as PlatformIOSStatic).isPad ? 'fullScreenModal' : 'modal';

const screens = [
  OpportunityScreens.SCANNER,
  OpportunityScreens.WALL,
  OpportunityScreens.IN_APP_BROWSER_WALL,
];

const OpportunityStack = ({ route, navigation }: any) => {
  const navigateToHub = route.params?.hub;

  useHideBottomBar({ route, navigation, screens });
  const initialRouteName = IS_CHROME_EXTENSION && !navigateToHub
    ? OpportunityScreens.CHROME_EXTENSION
    : OpportunityScreens.LIST;

  return (
    <Stack.Navigator initialRouteName={initialRouteName} screenOptions={{ headerShown: false }}>
      <Stack.Screen name={OpportunityScreens.LIST} component={LeadHub} />
      <Stack.Screen name={OpportunityScreens.SEARCH} component={LeadHubSearch} />

      <Stack.Screen name={OpportunityScreens.CREATE} component={CreateOpportunityStack} />

      <Stack.Screen
        name={OpportunityScreens.SCANNER}
        component={ScannerScreen}
        options={{ presentation, gestureEnabled: false }}
      />

      <Stack.Screen
        name={OpportunityScreens.WALL}
        component={OpportunityWallStack}
        options={{ presentation, gestureEnabled: false }}
      />

      <Stack.Screen
        name={OpportunityScreens.IN_APP_BROWSER}
        component={OldInAppBrowser}
        options={{
          presentation,
        }}
      />

      <Stack.Screen
        component={InAppBrowser}
        options={{ presentation }}
        name={OpportunityScreens.IN_APP_BROWSER_WALL}
      />

      <Stack.Screen
        component={ChromeExtension}
        name={OpportunityScreens.CHROME_EXTENSION}
      />
    </Stack.Navigator>
  );
};

export default OpportunityStack;
