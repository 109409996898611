import React, { useCallback } from 'react';
import Icon from 'react-native-vector-icons/MaterialIcons';
import colors from 'theme/colors';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import { clearActiveOpportunity } from 'domain/shared/store/slice/opportunity';
import { BUTTON_HIT_SLOP } from 'domain/shared/constants';
import { BorderlessButton } from '../touchable';

interface IProps {
  onPress?: () => void;
}

const CloseButton = (props: IProps) => {
  const { onPress } = props;
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    if (onPress) {
      onPress?.();
      return;
    }

    dispatch(clearActiveOpportunity());
    navigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [
          { 
            name: 'APP.OPPORTUNITIES',
            params: { hub: true }
          },
        ],
      }),
    );
  }, []);

  return (
    <BorderlessButton onPress={onPress || onClose} hitSlop={BUTTON_HIT_SLOP}>
      <Icon name="close" size={24} color={colors.WHITE} />
    </BorderlessButton>
  );
};

export default CloseButton;
