import { ChangeEvent, useState } from "react";

export default  function usePickFile() {
    const [value, setValue] = useState<File | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>();
  
    const handleReset = () => {
      setValue(undefined);
      setLoading(false);
    }

    const createInput = (onFinish?: (file: File) => void) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = '.pdf,image/*';
      input.style.display = 'none';

      setValue(undefined);
      setLoading(true);

      const handleCancel = () => {
        setLoading(false);
        input.remove();
      }
    
      input.onchange = async (event) => {
        try {
          const ev = event as unknown as ChangeEvent<HTMLInputElement>;

          const file = ev.target.files?.[0];
          if (!file) throw new Error("Not file");
          
          onFinish?.(file);
        } catch (e) {
          console.log(e);
        } finally {
          handleCancel();
        }
      };

      input.oncancel = handleCancel;
      input.click();
    }

    const createMultipleInput = async (onFinish?: (files: File[]) => void) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = '.pdf,image/*';
      input.style.display = 'none';
      input.multiple = true;

      setValue(undefined);
      setLoading(true);

      const handleCancel = () => {
        setLoading(false);
        input.remove();
      }
    
      input.onchange = async (event) => {
        try {
          const ev = event as unknown as ChangeEvent<HTMLInputElement>;

          let files: File[] = [];

          if (!ev.target.files || ev.target.files.length <= 0) throw new Error("Not file");

          for (let i = 0; i < ev.target.files?.length; i++) {
            const file = ev.target.files?.item(i);
            if (file) files.push(file);
          }

          if (files.length <= 0) throw new Error("Not file");
          
          onFinish?.(files);
        } catch (e) {
          console.log(e);
        } finally {
          handleCancel();
        }
      };

      input.oncancel = handleCancel;
      input.click();
    }
  
    const handleUploadFile = async () => {
      createInput((file) => setValue(file));
    };

    const handleUploadMultipleFilesPromise = async () => {
      return new Promise<File[]>((resolve) => {
        createMultipleInput((files) => resolve(files));
      })
    }

    const handleUploadFilePromise = async () => {
      return new Promise<File>((resolve) => {
        createInput((file) => resolve(file));
      })
    }
  
    return {
      value,
      loading,
      handleUploadFile,
      handleReset,
      handleUploadFilePromise,
      handleUploadMultipleFilesPromise,
    }
  }