import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import Modal from 'react-native-modal';
import styled from 'styled-components/native';
import { Flex } from '@kanvas/andromeda';
import { Button2 } from '../text';
import { TouchableOpacity } from 'components/atoms/touchable';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { ButtonBase } from 'components/atoms/button-with-icon';
import { translate } from 'locales';
import LeadsService from 'domain/shared/services/leads.service';
import WebView from 'react-native-webview';
import { ActivityIndicator, Linking } from 'react-native';
import colors from 'theme/colors';
import { ACTION_ENGINE_FILE_VIEWER } from 'env';
import { IS_CHROME_EXTENSION, IS_DESKTOP_WEB, IS_MOBILE, IS_MOBILE_WEB } from 'domain/shared/constants';
import CRMExtensionService from 'domain/shared/services/crm-extension-service';
import { OpenInAppModalBrowser } from 'domain/shared/utils/openInAppBrowser';


enum State {
  LOADING = 'loading',
  COMPLETED = 'completed',
  DISPLAY = 'display',
  RETRY = 'retry',
}

export type PullCreditModalRef = {
  execute: (leadId: string, options: string[]) => void;
};

const states = {
  [State.LOADING]: {
    text: translate('pullcredit.loading'),
  },
  [State.RETRY]: {
    text: translate('pullcredit.retry'),
  },
  [State.COMPLETED]: {
    text: translate('pullcredit.completed'),
  },
  [State.DISPLAY]: {
    text: '',
  }
}

const INJECTED_JAVASCRIPT = `(function() {
  const meta = document.createElement('meta'); 
  meta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no'); 
  meta.setAttribute('name', 'viewport'); 
  document.getElementsByTagName('head')[0].appendChild(meta);
})();`;

const Root = styled(Flex)`
  background-color: white;
  padding: 20px 16px;
  border-radius: 16px;
`;

const PdfRoot = styled(Flex)`
  background-color: white;
  border-radius: 16px;
  max-height: 80%;
  flex: 1;
  overflow: hidden;
`;

const View = styled(WebView)`
  overflow: hidden;
  flex: 1;
`;

const MessageContainer = styled(Flex)`
  padding: 20px 16px 0px;
`;

const DEFAULT_STATE = states[State.LOADING];
const PullCreditModal = forwardRef<PullCreditModalRef, { name: string, onRetry: VoidFunction, onSuccess?: VoidFunction, onDismiss?: (pullCreditId?: number) => void }>((props, ref) => {
  const [state, setState] = useState(DEFAULT_STATE);
  const [selectedState, setSelectedState] = useState(State.LOADING);
  const [openPullCreditModal, setOpenPullCreditModal] = useState(false);
  const [result, setResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState<string>();
  const [pullCreditId, setPullCreditId] = useState<number>();

  const handleDismiss = () => {
    setState(DEFAULT_STATE);
    setLoading(false);
    setResult(false);
    setUrl(undefined);
    props.onDismiss?.(pullCreditId);
  }

  const changeState = (current: State) => {
    setState(states[current]);
    setSelectedState(current);
  };

  const execute = async (leadId: string, options: string[]) =>{
    setOpenPullCreditModal(true);
    changeState(State.LOADING);
    try {
      const response = await LeadsService.PullCredit(leadId, options.join(','));

      // const link = `${ACTION_ENGINE_FILE_VIEWER}?lid=${leadId}&mid=${response.message_id}`;
      // setUrl(link);
      
      // Changes for 700-credit
      setUrl(response.iframe_url_digital_jacket);
      setPullCreditId(response.message_id);

      setOpenPullCreditModal(true);
      changeState(State.COMPLETED);
      props.onSuccess?.();
    } catch (e) {
      console.log(e)
      changeState(State.RETRY);
    }
  };

  const linkHandler = useCallback((link: string) => {
    if (IS_MOBILE_WEB || IS_MOBILE) {
      OpenInAppModalBrowser(link);
      return;
    }

    if (IS_CHROME_EXTENSION) {
      CRMExtensionService.openLinkOnModal(link, props.name || '');

      return;
    }

    if (IS_DESKTOP_WEB) {
      Linking.openURL(link);
      return;
    }
  }, []);

  const handleExecution = () => {
    linkHandler(`${url}&ia=1`);
    handleDismiss();
    setOpenPullCreditModal(false);
    
    // setResult(true);
    // setLoading(true);
    // changeState(State.DISPLAY);
  };

  const Message = useCallback(() => {
    return (
      <Flex row justify='space-between'>
        <Button2 text={state.text} />
        <TouchableOpacity onPress={() => setOpenPullCreditModal(false)}>
          <Icon name='close' size={24} />
        </TouchableOpacity>
      </Flex>
    );
  }, [state, setOpenPullCreditModal]);

  const Loading = useCallback(() => {
    if (!loading) return null;

    return (
      <Flex flex={1} align='center' justify='center'>
        <ActivityIndicator
          size="large"
          animating
          color={colors.ORANGE}
        />
      </Flex>
    )
  }, [loading]);
  
  useImperativeHandle(ref, () => ({ execute }));

  return (
   <>
     <Modal
      isVisible={openPullCreditModal}
      animationIn="fadeIn"
      animationOut="fadeOut"
      backdropOpacity={.4}
      onDismiss={handleDismiss}
    >
      <>
        {
          !result && (
            <Root gap={12}>
              <Message />

              {(!result && !!url) && <ButtonBase text={translate('seeResults')} onPress={handleExecution} />}
              {(!result && !url && selectedState !== State.LOADING) && <ButtonBase text={translate('pullcredit.cta')} onPress={props.onRetry} />}
            </Root>
          )
        }

        {
          result && (
            <PdfRoot gap={12}>
              <MessageContainer>
                <Message />
              </MessageContainer>
              <Loading />
              <View
                scalesPageToFit
                source={{ uri: url! }}
                allowsInlineMediaPlayback
                onLoad={() => setLoading(false)}
                showsHorizontalScrollIndicator={false}
                mediaPlaybackRequiresUserAction={false}
                injectedJavaScript={INJECTED_JAVASCRIPT}
              />
            </PdfRoot>
          )
        }
      </>
    </Modal>
   </>
  );
});

export default PullCreditModal;