/* eslint-disable max-len */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-unstable-nested-components */

// Modules
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components/native';
import { Flex, Spacer, Button } from '@kanvas/andromeda';
import Icon from 'react-native-vector-icons/MaterialIcons';
import IIcon from 'react-native-vector-icons/Ionicons';
import FIcon from 'react-native-vector-icons/FontAwesome';
import MIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { connect } from 'react-redux';
import { TextTransform } from 'react-native-localized-text';
import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

// Constants
import {
  IS_WEB,
  IS_MOBILE,
  IS_MOBILE_WEB,
  IS_DESKTOP_WEB,
  IS_CHROME_EXTENSION,
} from 'domain/shared/constants';

// Atoms
import BackButton from 'atoms/back-button';
import GradientBar from 'atoms/gradient-bar';
import Title from 'atoms/title';
import Text from 'atoms/text';
import ContactButton from 'components/atoms/ContactButton';
import ModelOption from 'atoms/ModelOption';
import ButtonWithIcon from 'components/atoms/button-with-icon';
import CloseButton from 'components/atoms/close-button';
import OfacButton from 'components/atoms/ofac-button';

// Types
import { LeadStatus } from 'domain/shared/types/lead';
import { ContactType } from 'domain/shared/types/contact-types';
import { DOWNLOAD_MERGED, UPLOAD_FILES, VIEW_FILES } from 'domain/shared/types/amplitude-actions';
import { File as FSFile } from 'domain/shared/types/file.interface';

// Molecules
import Popup from 'components/molecules/popup';

// Services
import CRMExtensionService from 'domain/shared/services/crm-extension-service';
import OpportunityService from 'domain/shared/services/opportunity-service';
import ActionEngineService from 'domain/shared/services/actionEngine-service';
import { navigateToStack } from 'domain/shared/services/navigation-service';

// Hooks
import useAuth from 'domain/shared/hooks/useAuth';
import { RouteStack, ScannerParams } from 'domain/create-opportunity/hooks/use-scanner-screen';
import { useCompanySettings } from 'domain/shared/hooks/useAppSettings';

// Screens
import Chronometer from 'domain/opportunity-wall/screens/wall/components/ProfileInfo/chronometer';
import { CLOSED_SESSIONS, POSIBLEMATCHES } from 'domain/shared/screens';
import { OpportunityScreens } from 'domain/opportunity-list/screens';
import { refreshLeadListEvent } from 'domain/opportunity-list/screens/opp-list';

// Organisms
import { CXTabBarContext } from 'components/organisms/CXTabBar/cx-tab-bar-context';

// Utils
import { showOverlay } from 'domain/shared/utils/cx-overlay';
import { sendAmplitudeEvent } from 'domain/shared/utils/amplitude';
import { OpenInAppModalBrowser } from 'domain/shared/utils/openInAppBrowser';

// Theme
import Colors from 'theme/colors';

// Evironment
import { ACTION_ENGINE_FILE_VIEWER } from 'env';

// Locales
import { translate } from 'locales';

// Store
import { clearActiveOpportunity } from 'domain/shared/store/slice/opportunity';
import { AppDispatch } from 'domain/shared/store';

// Assets
import OutlineRedBadge from 'assets/svgs/outline-red-badge';
import { BackHandler, Linking } from 'react-native';
import usePickFile from 'domain/opportunity-wall/screens/content-preview/hooks/use-pick-file';
import { showSuccess } from 'domain/shared/utils/toast';
import CustomFieldsApi from 'domain/shared/services/custom-fields';
import wait from 'domain/shared/utils/wait';
import useCheckIdVerification from './hook/use-check-id-verification';
import CobuyerList from './cobuyer-list';
import OfacModal, { OfacModalRef } from '../ofac-modal';
import TooltipMessage from '../tooltip-message';


const IS_MOBILE_OR_MOBILE_WEB = IS_MOBILE || IS_MOBILE_WEB;

interface IProps {
  activeSession: any;
  openModal: Function;
  actionCount: number;
  clearSession?: any;
  updateActiveSession: (opp: any) => void;
  openFV: React.Dispatch<React.SetStateAction<boolean>>;
}

interface BodyPopupProps {
  showMerge: boolean;
  onViewFilePress: () => void;
  onUploadFilePress: () => void;
  onMergePress: () => void;
  fileUpload: boolean;
}

interface BuyerOptionsModalProps {
  onMainBuyerSelected?: () => void;
  cobuyers: any[];
  buyerName: string;
  onAddCoBuyer?: () => void;
}

const ICON_SIZE = 20;

const Container = styled(Flex)`
  width: 100%;
`;

const FileButton = styled(ButtonWithIcon)`
    width: 80px;
    height: 48px;
    padding-vertical: 8px;
    padding-horizontal: 12px;
    border-radius: 12px;
    border-width: 1px;
    border-color: ${Colors.BORDER_COLOR};
`;

const ContactContainer = styled(Flex)`
  padding-bottom: 11px;
`;

const IconButton = styled(MIcon)`
    cursor:pointer;
`;

const ModalOptionsContainer = styled(Flex)`
  margin-bottom: 40px;
`;

const IconContainer = styled.View`
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const BodyPopup = memo(({ onMergePress, onViewFilePress, onUploadFilePress, showMerge, fileUpload }: BodyPopupProps) => (
  <>
    <ButtonWithIcon
      text="View Files"
      textColor={Colors.ORANGE}
      color={Colors.WHITE}
      style={{ marginVertical: 12 }}
      onPress={onViewFilePress}
    />
    <ButtonWithIcon
      text="Upload Files"
      textColor={Colors.ORANGE}
      color={Colors.WHITE}
      style={{ marginBottom: 12 }}
      onPress={onUploadFilePress}
      loading={fileUpload}
    />
    <ButtonWithIcon
      text="Download Merged"
      enabled={showMerge}
      textColor={showMerge ? Colors.ORANGE : Colors.DISABLED}
      color={showMerge ? Colors.WHITE : Colors.DISABLED_BUTTON_BACKGROUND}
      onPress={showMerge ? onMergePress : undefined}
    />
  </>
));

function Verified({ verified, iconSize = 16 }: { verified?: any, iconSize?: number }) {
  const { icon = '', color = '' } = verified || {};

  if (!verified) return null;
  if (icon === 'shield-off') return <OutlineRedBadge size={iconSize} />;

  return <MIcon size={iconSize} name={icon} color={color} />;
}

const BuyerOptionsModal = (props: BuyerOptionsModalProps) => {
  const {
    onMainBuyerSelected,
    onAddCoBuyer,
    cobuyers,
    buyerName,
  } = props;

  return (
    <ModalOptionsContainer marginHorizontal={12} align="center">
      <ButtonWithIcon
        text={buyerName}
        textColor={Colors.ORANGE}
        color={Colors.WHITE}
        style={{ marginVertical: 8 }}
        onPress={onMainBuyerSelected}
      />

      {cobuyers.length > 0 && (
        <ButtonWithIcon
          text={cobuyers[0]?.name}
          textColor={Colors.ORANGE}
          color={Colors.WHITE}
          style={{ marginVertical: 8 }}
          onPress={onMainBuyerSelected}
        />
      )}

      <ButtonWithIcon
        text={translate('addCobuyer', TextTransform.CAPITAL)}
        textColor={Colors.BLACK}
        color={Colors.WHITE}
        style={{ marginVertical: 8 }}
        onPress={onAddCoBuyer}
      />

    </ModalOptionsContainer>
  );
};

const PRODUCT_SOLD = '[Product] Sold';

const CXWallHeader = (props: IProps) => {
  // Props
  const {
    activeSession,
    openModal,
    actionCount,
    clearSession,
    updateActiveSession,
    openFV,
  } = props;

  // Hooks
  const { params = {} as any } = useRoute();
  const { hideSync = false } = params;
  const { currentStore, user } = useAuth();
  const insets = useSafeAreaInsets();
  const companySettings = useCompanySettings();
  const navigation = useNavigation();

  // Constants
  const company_id = currentStore?.companies_id!;
  const userId = user?.id!;
  const paddingLeft = insets.left;
  const paddingRight = insets.right;
  const participant_relationship_default_id = companySettings?.participant_relationship_default_id || '0';
  const {
    people = {},
    is_chrono_running = 0,
    owner = {},
    leads_status = {},
    uuid,
    title,
    firstname,
    lastname,
    companies: {
      uuid: companyUuid = '',
    } = {},
    participants = [],
  } = activeSession;

  // Context
  const { CRMhost } = useContext(CXTabBarContext);

  // Refs
  const timerHandler = useRef<any>(null);
  const ofacModalRef = useRef<OfacModalRef>(null);

  // States
  const [showModal, setShowModal] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const [passedPullCredit, setPassedPullCredit] = useState<boolean | null>(null);
  const [showBuyerOptions, setShowBuyerOptions] = useState(false);

  const [fileUpload, setFileUpload] = useState(false);
  const { handleUploadMultipleFilesPromise } = usePickFile();

  const isManager = useMemo(() => {
    const { manager = undefined } = user?.access_list?.settingsmenu || {};
    return manager !== 0;
  }, []);

  const { id_verification } = activeSession.custom_fields || {};

  const check = useCheckIdVerification(isManager);
  const isVerified = !!id_verification;

  const vehicle = useMemo(() => {
    if (!activeSession) return null;

    const { vehicle_of_interest = null } = activeSession?.custom_fields || {} as Record<string, any>;
    if (!vehicle_of_interest) return undefined;

    const { make, model, yearFrom, isNew } = vehicle_of_interest;

    const status = isNew ? '(New)' : '(Used)';
    const vehicle = [yearFrom, make, model, status].join(' ').trim();
    return vehicle;
  }, [activeSession]);

  const showFileButton = useMemo(() => {
    if (IS_MOBILE_OR_MOBILE_WEB) return true;
    if (!user) return true;
    if (!user.access_list.fileviewer) return true;
    return user.access_list.fileviewer.can_view !== 0;
  }, [user]);

  const verfied = useMemo(() => check(id_verification), [user, currentStore, isVerified, id_verification, isManager]);

  const cobuyers = useMemo(() => {
    if (participants.length <= 0) return [];
    return participants.filter((p: any) => p.relationship === participant_relationship_default_id);
  }, [participants]);

  const handleVerified = () => {
    setShowBuyerOptions(true);
  };

  /**
   * Handles the selection of the main buyer.
   * Hides the buyer options, waits for 1 second, and then navigates to the appropriate scanner screen.
   */
  const onMainBuyerSelected = () => {
    setShowBuyerOptions(false);

    wait(1000).then(() => {
      const hadIdScannerAccess = !!companySettings?.id_verification;
      const scanParams: ScannerParams = { type: 'wall' };
      const { SCANNER, ID_SCANNER } = OpportunityScreens;
      // @ts-ignore
      navigation.navigate(hadIdScannerAccess ? ID_SCANNER : SCANNER, scanParams);
    });
  };

  const onAddCoBuyer = () => {
    setShowBuyerOptions(false);

    wait(1000).then(() => {
      const { SCANNER, ID_SCANNER } = OpportunityScreens;
      // @ts-ignore
      navigation.navigate(ID_SCANNER, { addCobuyer: true, lead: people, activeSession });
      // navigation.navigate(OpportunityScreens.ADD_COBUYER, {
      //   lead: people,
      //   activeSession,
      // });
    });
  };

  const handleBack = useCallback(() => {
    clearSession();
    if (params.refresh) refreshLeadListEvent();

    if (IS_CHROME_EXTENSION) {
      navigation.navigate(CLOSED_SESSIONS as never);
      return undefined;
    }

    // if (navigation.canGoBack() && !IS_WEB) {
    //   navigation.goBack();
    //   return undefined;
    // }
    
    navigation.navigate(CLOSED_SESSIONS as never);
    return undefined;
  }, [navigation, params]);

  const onUploadFilePress = useCallback(async () => {
    await wait(200);

    try {
      if (!activeSession) return;
      
      const { files = [] } = activeSession;
      
      const name = 'MISC';
      const sequence = files.filter((file: FSFile) => file.field_name?.includes(name)).length + 1;

      const uploadedFiles = await handleUploadMultipleFilesPromise();
      setFileUpload(true);

      const { id, title } = activeSession;
      let newFiles = [...files];

      await Promise.all(uploadedFiles.map(async (file: any, index) => {
        const completeName = `${name}_${(sequence + index).toString().padStart(3, '0')}`;
        const extension = file.name.split('.').slice(-1);
        const [response] = await OpportunityService.uploadPhoto(file, completeName, `${completeName}.${extension}`);

        newFiles.push({ field_name: completeName, filesystem_id: response.id, id: 0, title })
      }))
      
      sendAmplitudeEvent(UPLOAD_FILES, { event: UPLOAD_FILES, leadId: uuid });

      const opp = await OpportunityService.associateFileToOpp(newFiles, id, title);

      updateActiveSession(opp)

      setFileUpload(false);
      setShowModal(false);
      await wait(400);
      showSuccess(translate('uploadMultipleMessage'));
    } catch (err: any) {
      console.log(err);
      setFileUpload(false);
    }
  }, [uuid, activeSession]);
  
  useFocusEffect(useCallback(function () {
    function onBackPress() {
      navigation.navigate(CLOSED_SESSIONS as never);
      return true;
    }

    const subscription = BackHandler.addEventListener(
      'hardwareBackPress',
      onBackPress
    );

    return () => subscription.remove();
  }, [activeSession]));


  const onViewFilePress = useCallback(() => {
    sendAmplitudeEvent(VIEW_FILES, { event: VIEW_FILES, leadId: uuid });

    const link = `${ACTION_ENGINE_FILE_VIEWER}?lid=${uuid}`;

    if (IS_MOBILE_WEB || IS_MOBILE) {
      setTimeout(() => {
        OpenInAppModalBrowser(link);
      }, 600);
      setShowModal(false);
      return;
    }
    
    if (IS_CHROME_EXTENSION) {
      CRMExtensionService.openFileViewer(link, title || '');
      setShowModal(false);
      return;
    }

    if (IS_DESKTOP_WEB) {
      Linking.openURL(link);
      setShowModal(false);
      return;
    }
  }, [uuid, title]);

  const onMergePress = useCallback(async () => {
    sendAmplitudeEvent(DOWNLOAD_MERGED, { event: DOWNLOAD_MERGED, leadId: uuid });
    setShowModal(false);
    showOverlay(true, { opacity: 0.7, text: translate('mergingFiles') });
    await OpportunityService.mergeAllFiles(uuid, `${firstname}_${lastname}`);
    showOverlay(false);
  }, [uuid, firstname, lastname]);

  const executeOfac = async () => {
    ofacModalRef.current?.execute(uuid);
  };

  const syncOpp = async () => {
    const { sync } = activeSession;
    showOverlay(true);
    if (sync) {
      const response = await ActionEngineService
        .syncELeads({ ...sync, company: companyUuid }, true);
      navigateToStack(
        OpportunityScreens.WALL,
        POSIBLEMATCHES,
        {
          items: response, args: sync, FromOppWall: true, action: 'Open',
        },
      );
    }
    showOverlay(false);
  };

  useEffect(() => {
    async function getPullCredit() {
      const { customFields } = await CustomFieldsApi.get({ entity_id: uuid, name: 'check_list_status' });

      if (customFields) {
        const { pull_credit_pass = null } = customFields;
        
        if (pull_credit_pass !== null) {
          setPassedPullCredit(Boolean(customFields.pull_credit_pass));
        } else {
          setPassedPullCredit(null);
        }
      }
    }

    getPullCredit()
  }, [uuid]);

  useEffect(() => {
    OpportunityService.getFiles(uuid).then((data) => {
      if (!data) return;
      setShow(data.length > 0);
    });
  }, [uuid]);

  useFocusEffect(useCallback(function () {
    function onBackPress() {
      navigation.navigate(CLOSED_SESSIONS as never);
      return true;
    }

    const subscription = BackHandler.addEventListener(
      'hardwareBackPress',
      onBackPress
    );

    return () => subscription.remove();
  }, [activeSession]));

  return (
    <Container>
      <OfacModal name={people?.name} ref={ofacModalRef} />
      <GradientBar>
        <Flex
          padding={16}
          style={{
            paddingLeft: paddingLeft || 16,
            paddingRight: paddingRight || 16,
          }}
        >
          <Flex row align="center">
            <Flex>
              {
                !IS_WEB
                  ? <BackButton onBack={handleBack} />
                  : <CloseButton  />
              }
            </Flex>
            <Flex flex={1} gap={12} row align="center" justify="flex-end">
              {(IS_MOBILE_WEB || IS_MOBILE) && (
                <Flex style={{ height: 36 }}>
                  <Chronometer ref={timerHandler} contact={activeSession} />
                </Flex>
              )}

              {(IS_MOBILE && companySettings?.id_verification) && (
                <Button.Icon
                  style={{ padding: 0 }}
                  onPress={handleVerified}
                  Icon={<MIcon size={24} name="shield-check-outline" color={Colors.WHITE} />}
                />
              )}

              {(isManager && companySettings?.ofac) && <Button.Icon Icon={<OfacButton />} onPress={executeOfac} />}

              {
                (IS_CHROME_EXTENSION && !hideSync && CRMhost === 'eLeads') && (
                  <TooltipMessage>
                    <Button.Icon
                      style={{ padding: 0 }}
                      onPress={() => syncOpp()}
                      Icon={<IconButton name="sync" size={24} color={Colors.WHITE} />}
                    />
                  </TooltipMessage>
                )
              }

              {
                (IS_WEB || IS_MOBILE) && (
                  <Button.Icon
                    onPress={() => openModal()}
                    style={{ padding: 0 }}
                    Icon={<IconButton name="pencil-outline" size={24} color={Colors.WHITE} />}
                  />
                )
              }
            </Flex>
          </Flex>
          <Spacer size={14} />
          <Flex row align="center">
            <Flex flex={2} row>
              <Title
                text={activeSession?.people?.name || ''}
                color={Colors.WHITE}
                numberOfLines={1}
              />
              <Spacer horizontal size={4} />
              <IconContainer>
                {leads_status?.id === LeadStatus.WON && <Icon name="checkmark-circle-sharp" size={ICON_SIZE} color={Colors.WHITE} />}
                {leads_status?.id === LeadStatus.LOST && <Icon name="ios-close-circle-sharp" size={ICON_SIZE} color={Colors.WHITE} />}
                {(is_chrono_running === LeadStatus.CHRONO && !IS_WEB) && <IIcon name="md-location-sharp" size={ICON_SIZE} color={Colors.WHITE} />}

                <Verified verified={verfied} iconSize={ICON_SIZE} />
                {passedPullCredit !== null && <FIcon name="flag" size={ICON_SIZE} color={passedPullCredit ? Colors.ID_VERIFICATION.GREEN : Colors.ID_VERIFICATION.RED} style={{ marginLeft: 6 }} />}
              </IconContainer>
            </Flex>
            <Flex />

            {showFileButton && (
              <Flex style={{ height: 36 }} justify="flex-end" align="flex-end" flex={1}>
                <FileButton
                  onPress={IS_MOBILE_OR_MOBILE_WEB
                    ? () => openFV(true) : () => setShowModal(true)}
                  text="Files"
                  type="button2"
                  textColor={Colors.ORANGE}
                  icon={<MIcon name="folder-open-outline" size={16} color={Colors.ORANGE} />}
                  color={Colors.WHITE}
                />
              </Flex>
            )}
          </Flex>

          <Flex>
            {/* <Flex row gap={2} align="center">
              <Text size={12} lineHeight={16} color={Colors.WHITE} text={`Customer: ${people?.name?.trim()}`} />
              <Verified verified={verfied} />
            </Flex> */}

            <CobuyerList coBuyers={cobuyers} isManager={isManager} />

            {IS_MOBILE_OR_MOBILE_WEB && (
              <Text size={12} lineHeight={16} color={Colors.WHITE} text={`Owner: ${owner?.full_name}`} />
            )}

            {vehicle && (
              <Text size={12} lineHeight={16} color={Colors.WHITE} text={vehicle} />
            )}

            {(!IS_WEB && (leads_status?.id === LeadStatus.WON)) && (
              <Text size={14} lineHeight={16} color={Colors.WHITE} text={PRODUCT_SOLD} />
            )}

            <Text size={12} lineHeight={16} color={Colors.WHITE} text={`${actionCount || 0} Actions`} />
          </Flex>
        </Flex>

        {IS_MOBILE_OR_MOBILE_WEB && (
          <ContactContainer
            marginHorizontal={16}
            justify="space-between"
            align="center"
            row
            style={{ paddingLeft, paddingRight }}
          >
            {(people?.phone !== '' && people?.phone != null) && (
              <ContactButton
                rpadding
                contact={people?.phone}
                type={ContactType.PHONE}
                iconName="phone-outline"
                userId={userId}
                company_id={company_id}
              />
            )}
            {(people?.phone !== '' && people?.phone != null)
              && (
                <ContactButton
                  rpadding={people?.email !== ''}
                  contact={people?.phone}
                  type={ContactType.MESSAGE}
                  iconName="chat-processing-outline"
                  userId={userId}
                  company_id={company_id}
                />
              )}
            {(people?.email !== '' && people?.email != null) && (
              <ContactButton
                contact={people?.email}
                type={ContactType.EMAIL}
                iconName="email-outline"
                userId={userId}
                company_id={company_id}
              />
            )}
          </ContactContainer>
        )}
      </GradientBar>

      <Popup
        visible={showModal}
        Body={() => (
          <BodyPopup
            showMerge={show}
            onViewFilePress={onViewFilePress}
            onMergePress={onMergePress}
            onUploadFilePress={onUploadFilePress}
            fileUpload={fileUpload}
          />
        )}
        close={() => setShowModal(false)}
        showCloseButton
        titleText={translate('chooseAction')}
        font="Header3"
        showIconTitle={false}
      />

      {/* Modals */}
      <ModelOption
        header={false}
        isVisible={showBuyerOptions}
        onPressClose={() => setShowBuyerOptions(false)}
        children={(
          <BuyerOptionsModal
            onMainBuyerSelected={onMainBuyerSelected}
            onAddCoBuyer={onAddCoBuyer}
            cobuyers={cobuyers}
            buyerName={`${firstname} ${lastname}`}
          />
        )}
      />

    </Container>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  clearSession: () => dispatch(clearActiveOpportunity()),
});

export default connect(null, mapDispatchToProps)(CXWallHeader);